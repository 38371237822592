// Padding for Hero Elements above
document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("[data-hero]")) {
    const checkOverlap = () => {
      let heroContainer = document.querySelector("[data-hero]");
      let main = document.querySelector("[data-main]");
      let heroBottom = heroContainer.getBoundingClientRect().bottom;

      let mainTop = main.getBoundingClientRect().top;

      if (heroBottom >= mainTop) {
        main.style.paddingTop = `${heroBottom - mainTop + 15}px`;
      } else {
        main.style.paddingTop = "0";
      }
    };
    window.addEventListener("scroll", () => {
      checkOverlap();
    });
    window.addEventListener("resize", () => {
      checkOverlap();
    });
    checkOverlap();
  }
});
