/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/main";
import "@js/parts/lazyloading";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";

window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.start();

// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
    // console.log("this is a test!");
  });
}
